export const VERIFY = {
    CONTACT_NO_PATTERN: /[- )(]/g,
}

export const ATTEMPTS = 2;

export const HTTP_STATUS = {
    OK: 200,
    ACCEPTED: 202,
    BAD_REQUEST: 400,
    UNAUTHORISED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    UNPROCESSABLE_ENTITY: 422,
    INTERNAL_SERVER_ERROR: 500,
};

export const ERROR_CODE = {
    PERMISSION_DENIED: "100000",
    FAIL_CREATE_TOKEN: "100001",
    INVALID_PASSWORD: "100002",
    ACCOUNT_EXIST: "100003",
    CREDENTIAL_CONFLICT: "100004",
    ACCOUNT_NOT_EXIST: "100005",
    ACCOUNT_CREATE_FAIL: "100006",
    ACCOUNT_MODIFY_FAIL: "100007",
    ACCOUNT_DEL_FAIL: "100008",
    PASSWORD_CRITERIA_FAIL: "100009",
    ACCOUNT_SET_PASSWORD_FAIL: "100010",
    REFRESH_EXPIRED: "100011",
    VAULT_JSON_EXCEED_LIMIT: "100012",
    VAULT_JSON_INVALID: "100013",
    VAULT_EXCEED_LIMIT: "100014",
    USER_INACTIVE: "100015",
    USER_LOGOUT: "100016",
    ACCOUNT_LOCKED: "100017",
    USER_DUPLICATE_LOGIN: "100018",
    TOO_EARLY_REFRESH: "100019",
    OTP_FAIL_TO_SAVE: "100101",
    OTP_EXPIRES: "100102",
    OTP_INVALID: "100103",
    OTP_WRONG_PURPOSE: "100104",
    OTP_EXCEED_LIMIT: "100105",
    VALIDATION_ERROR: "44003",
    ACCESS_DENIED: "44404",
    EXISTING_MEMBER: "103004",
    DATA_NOT_AVAILABLE: "102006",
    NO_ACTIVITY: "100020",
    DOCS_STILL_CREATING: "103023",
    CANNOT_DECRYPT: "102011",
    FAIL_ASSERTION: "102001",
};

export const URLS = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    BUCKET_URL: process.env.REACT_APP_BUCKET_URL,
    API_VERSION: "v1/",
    ACCOUNT_CONTEXT: "profile/",
    IDENTITY_CONTEXT: "identity/",
    REGISTRATION: "register/request",
    VERIFICATIONCODE: "register/confirm",
    SUBMITVERIFY: "submitidverify",
    BIOMETRICS: "getsocialbiometrics/%1",
    REQUEST_BIOMETRICS: "getsocialbiometrics/%1/request",
    VERIFY_BIOMETRICS: "getsocialbiometrics/%1/verify",
    JSON_PROVINCE: "language/en/province.json",
};

export const FIInformation = {
    // Community Savings Credit Union
    FI_DISPLAY_NAME: "CSCU",
    FI_FULLNAME: "Community Savings Credit Union",
    FI_CONDITIONS: "https://www.comsavings.com/legal/membership-application-consents",
    FI_TEL: "+16046542000",
    FI_TEL_PRETTY: "(604) 654-2000",
    FI_EMAIL: "mailto:reception@comsavings.com",
    FI_EMAIL_PRETTY: "reception@comsavings.com",

    // Mobetize Branding
    // FI_DISPLAY_NAME: "Mobetize",
    // FI_FULLNAME: "Mobetize",
    // FI_CONDITIONS: "https://www.mobetize.com/privacy-policy/",
    // FI_TEL: "+16045555555",
    // FI_TEL_PRETTY: "(604) 555-5555",
    // FI_EMAIL: "mailto:someone@yoursite.com",
    // FI_EMAIL_PRETTY: "someone@yoursite.com",
    config: {
        verificationCountry: "CA"
    }
};

export const API_KEY = process.env.REACT_APP_API_KEY;

const COMMON_BASE_URL = URLS.BASE_URL + URLS.ACCOUNT_CONTEXT + URLS.API_VERSION;
const IDENTITY_URL = URLS.BASE_URL + URLS.IDENTITY_CONTEXT + URLS.API_VERSION;

export const URL_PROVINCE = URLS.BUCKET_URL + URLS.JSON_PROVINCE;

export const URL_REGISTER = COMMON_BASE_URL + URLS.REGISTRATION;
export const URL_VERIFY = COMMON_BASE_URL + URLS.VERIFICATIONCODE;
export const URL_BIOMETRICS = IDENTITY_URL + URLS.BIOMETRICS;
export const URL_REQUEST_BIOMETRICS = IDENTITY_URL + URLS.REQUEST_BIOMETRICS;
export const URL_VERIFY_BIOMETRICS = IDENTITY_URL + URLS.VERIFY_BIOMETRICS;
export const URL_SUBMIT_VERIFY = IDENTITY_URL + URLS.SUBMITVERIFY;

export const S_INSTNT_TXN_ID = "S_INSTNT_TXN_ID";
export const S_INSTNT_FINGER_PRINT = "S_INSTNT_FINGER_PRINT";

export const POSTAL = {
    CA_POSTALMASK: [/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/],
    US_POSTALMASK: [/\d/, /\d/, /\d/, /\d/, /\d/],
    CA_POSTALPATTERN: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z]( )\d[ABCEGHJ-NPRSTV-Z]\d$/i,
    US_POSTALPATTERN: /^\d{5}$/,
}